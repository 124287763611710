import { createSlice } from '@reduxjs/toolkit';

interface Cargo {
    id: number;
    codigo: string;
    nombre: string;
}

interface Representacion {
    id: number;
    nombre: string;
}

interface TipoContratacion {
    id: number;
    nombre: string;
}

interface ParametricDataState {
    data: {
        cargos: Cargo[];
        representaciones: Representacion[];
        tiposContratacion: TipoContratacion[];
    };
}

const initialState: ParametricDataState = { 
    data: {
        cargos: [],
        representaciones: [],
        tiposContratacion: []
    }
};

const datosParametricosSlice = createSlice({
    name: 'datosParametricos',
    initialState,
    reducers: {
        setParametricData: (state, { payload }) => {
            
            state.data = payload;
        }
    }
});

export default datosParametricosSlice.reducer;
export const { setParametricData } = datosParametricosSlice.actions;
