import React, { useEffect, useState } from 'react';
import {
    Grid,
    Checkbox,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Box,
    Button
} from '@mui/material';
import SectionHeader from '../../../components/SectionHeader';
import styles from '../EmpresasAsignacion/style.module.css';
import Alert from '../../../components/Alert/AlertDialog';
import { useLocation } from 'react-router-dom';
import Loading from '../../../components/Loading';
import useNomina from './hooks/useNomina';
import NominaAssignmentsFilters from '../components/NominaAssignmentsFilters';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../contexts/TitleContext';

const NominaAsignacion: React.FC = () => {
    const { t } = useTranslation('employees');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const location = useLocation();
    const organismsNames = location.state?.dataOrganismo?.nombre;
    const organismsId = location.state?.dataOrganismo?.organismo?.id;
    const contractNumber = location.state?.dataOrganismo?.organismo?.organismoCentral?.contrato;
    const idEstablecimiento = location.state?.dataOrganismo?.id;
    const { setTitle } = useTitle();
    

    const {
        data,
        totalRecords,
        selectedEmpleados,
        isLoading,
        error,
        handleSelectEmpleado,
        handleGuardar,
        handleClearFilters,
        handleBuscar,
        handleClearSelection
    } = useNomina(contractNumber,idEstablecimiento, rowsPerPage, page, organismsId  );

    useEffect(() => {
        setTitle(t('AssignmentNomina.page_title'));
    }, [setTitle]);
    
    const handleRefreshGrid = () => {
        handleBuscar('', '', '');
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Grid container sx={{background:'#fff', mb:3, p:2}} >
                <SectionHeader>
                    <SectionHeader.Title>
                    {t('AssignmentNomina.page_title')}: {organismsNames}
                    </SectionHeader.Title>
                </SectionHeader>
                
                <Grid item xs={12}>
                    <Grid sx={{p:2,}}>
                        <Typography variant="h6" sx={{ mb: 2 }}>{t('AssignmentNomina.filter_by')}</Typography>
                        <NominaAssignmentsFilters 
                            onSearch={handleBuscar} 
                            onClearFilters={handleClearFilters} 
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h4" sx={{ fontWeight: 600, mb: 3, fontSize: '18px', pl: 3, pt: 3 }}>
                        {t('AssignmentNomina.nomina_contrato')}
                        </Typography>
                        <Table size="small">
                            <TableHead className={styles.tableHead}>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t('AssignmentNomina.first_name')}</TableCell>
                                    <TableCell>{t('AssignmentNomina.cuil')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            <Loading size="medium" /> 
                                        </TableCell>
                                    </TableRow>
                                ) : error ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center" style={{ color: 'red' }}>
                                            {t('AssignmentNomina.error_loading_nomina')}
                                        </TableCell>
                                    </TableRow>
                                ) : Array.isArray(data?.data?.data) && data?.data?.data.length > 0 ? (
                                    data.data.data.map((empleado: any) => (
                                        <TableRow key={empleado.id}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedEmpleados.some((e) => e.id === empleado.id)}
                                                    onChange={() => handleSelectEmpleado(empleado)}
                                                />
                                            </TableCell>
                                            <TableCell>{empleado.nombre}</TableCell>
                                            <TableCell>{empleado.cuil}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                           <Typography sx={{fontWeight: 'bold'}}>
                                                {t('AssignmentNomina.no_nomina_data')}
                                           </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={totalRecords}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Registros por página"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={5}>
                    <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h4" sx={{ fontWeight: 600, mb: 3, fontSize: '18px', pl: 3, pt: 3 }}>
                        {t('AssignmentNomina.nomina')} {organismsNames}
                        </Typography>
                        <Table size="small" className={styles.smallTable}>
                            <TableHead className={styles.tableHead}>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{t('AssignmentNomina.first_name')}</TableCell>
                                    <TableCell>{t('AssignmentNomina.cuil')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedEmpleados.map((empleado) => (
                                    <TableRow key={empleado.id}>
                                        <TableCell>
                                            <Checkbox
                                                checked={true}
                                                onChange={() => handleSelectEmpleado(empleado)} 
                                            />
                                        </TableCell>
                                        <TableCell>{empleado.nombre}</TableCell>
                                        <TableCell>{empleado.cuil}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2,
                                }}
                            >
                             <Button
                                variant="text"
                                color="primary"
                                onClick={handleClearSelection}
                                sx={{ mr: 2 }}
                            >
                              {t('AssignmentNomina.clear_selection')}
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => handleGuardar(idEstablecimiento)}>
                             {t('AssignmentNomina.save')}    
                            </Button>                         
                        </Box>
                       </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Alert />
        </>
    );
};

export default NominaAsignacion;
