import { useAppSelector } from './reduxHooks';

interface Funcionalidad {
    id: number;
    nombre: string;
    urlRedireccion: string;
    visualizarMenu: boolean;
}

interface Grupo {
    id: number;
    nombre: string;
    funcionalidades: Funcionalidad[];
    orden: number;
}

interface Access {
    [key: number]: {
        [key: number]: {
            name: string;
            url: string;
            showInMenu: boolean;
        };
    };
}

export function useUserAccess(): Access | null {
    const userFunctions: Grupo[] | undefined = useAppSelector(
        (state: any) => state.auth.user?.funcionalidadesAgrupadas
    );

    let access: Access = {};

    if (userFunctions) {
        userFunctions.forEach((group) => {
            let functionalities: Access[0] = {};

            group.funcionalidades.forEach((func) => {
                functionalities[func.id] = {
                    name: func.nombre,
                    url: func.urlRedireccion,
                    showInMenu: func.visualizarMenu
                };
            });

            access[group.id] = functionalities;
        });
    }

    return access || null;
}

export default useUserAccess;
