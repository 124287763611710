import React, { useState } from 'react';
import { Grid, TextField, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next'; 

interface NominaAssignmentsFiltersProps {
    onSearch: (nombre: string, apellido: string, cuil: string) => void;
    onClearFilters: () => void;
}

const NominaAssignmentsFilters: React.FC<NominaAssignmentsFiltersProps> = ({ onSearch, onClearFilters }) => {
    const { t } = useTranslation('employees');
    const [nombre, setNombre] = useState<string>('');
    const [apellido, setApellido] = useState<string>('');
    const [cuil, setCuil] = useState<string>('');

    const handleSearch = () => {
        onSearch(nombre, apellido, cuil);
    };

    const handleClear = () => {
        setNombre('');
        setApellido('');
        setCuil('');
        onClearFilters();
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    label={t('AssignmentNomina.first_name')}
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    fullWidth
                />
            </Grid>           
            <Grid item xs={6}>
                <TextField
                    label={t('AssignmentNomina.cuil')}
                    value={cuil}
                    onChange={(e) => setCuil(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 2,
                    }}
                >
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleClear}
                        sx={{ mr: 2 }}
                    >
                         {t('AssignmentNomina.clear_filters')}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={handleSearch}>
                    {t('AssignmentNomina.search')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default NominaAssignmentsFilters;
