import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { logout, tokenReceived } from '../features/auth/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';

export interface getPdfRrglResponse {
    data: {
        presignedUrls: string[];
    };
}

interface GetNominaDownloadUrlResponse {
    data: {
        presignedUrls: string[];
    };
}


interface PresentationType {
    id: number;
    nombre: string;
    titulo: string;
    detalle: string;
    activo: boolean;
    solapas: any;
}

interface CreatePresentationRequest {
    idTipoPresentacion: number;
    idEstablecimiento: number;
    esBorrador: boolean;
    datosAdicionales: {
        superficie: number;
        personalAdministracion: number;
        personalProduccion: number;
        actividad: string;
    };
    respuestas: string[];
    datosGremiales: string[];
    contratistas: string[];
    responsables: string[];
}

interface Cargo {
    id: number;
    codigo: string;
    nombre: string;
}

interface Representacion {
    id: number;
    nombre: string;
}

interface TipoContratacion {
    id: number;
    nombre: string;
}

interface ParametricDataResponse {
    data: {
        cargos: Cargo[];
        representaciones: Representacion[];
        tiposContratacion: TipoContratacion[];
    };
}

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const { auth }: any = api.getState();
        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh-token',
                method: 'POST',
                credentials: 'include',
                body: { username: auth?.user?.usuario }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            api.dispatch(tokenReceived(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const presentacionesApi = createApi({
    reducerPath: 'presentaciones',
    tagTypes: ['Presentaciones', 'PresentacionById', 'TiposPresentaciones'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getTiposPresentaciones: builder.query<PresentationType[], void>({
            query: () => ({ url: 'presentaciones/tipos' }),
            providesTags: ['TiposPresentaciones'],
            transformResponse: (response: { data: any }) => response?.data?.tipos ?? []
        }),
        getTipoPresentacion: builder.query<PresentationType, number>({
            query: (idTipo) => ({ url: `presentaciones/tipos/${idTipo}` }),
            providesTags: ['TiposPresentaciones'],
            transformResponse: (response: { data: any }) => response?.data
        }),
        createPresentacion: builder.mutation({
            query: (body: CreatePresentationRequest) => ({
                url: 'presentaciones',
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['Presentaciones']
        }),
        getPresentacionesByEstablecimiento: builder.query({
            query: ({ idEstablecimiento, take, skip, idTipo }) => ({
                url: `presentaciones`,
                params: { idEstablecimiento, take, skip, idTipo }
            }),
            providesTags: ['Presentaciones']
        }),
        getPresentacion: builder.query({
            query: (idPresentacion) => ({ url: `presentaciones/${idPresentacion}` }),
            providesTags: ['PresentacionById'],
            transformResponse: (response: { data: any }) => response?.data
        }),
        editPresentacion: builder.mutation({
            query: ({ PresentacionId, body }) => ({
                url: `presentaciones/${PresentacionId}`,
                method: 'PATCH',
                body: body
            }),
            invalidatesTags: ['PresentacionById']
        }),
        updatePresentationState: builder.mutation({
            query: ({
                presentationId,
                body
            }: {
                presentationId: number;
                body: { idEstado: any };
            }) => ({
                url: `presentaciones/${presentationId}/estados`,
                method: 'PATCH',
                body
            })
        }),
        // deletePresentation: builder.mutation({
        //     query: ({ idPresentation }) => ({
        //         url: `/presentaciones/seccion/${idPresentation}/`,
        //         method: 'DELETE'
        //     })
        // }),
        getDatosParametricos: builder.query<ParametricDataResponse, void>({
            query: () => ({
                url: 'presentaciones/datos-parametricos'
            })
        }),
        getPesentationsIndicators: builder.query({
            query: () => ({
                url: `presentaciones/indicadores`
            }),
            providesTags: ['Presentaciones'],
            transformResponse: (response: { data: any }) => response?.data
        }),
        getPresentacionRGRLPDFbyId: builder.query<getPdfRrglResponse, { idPresentacion: number, typeId: number }>({
            query: ({ idPresentacion, typeId }) => ({
                url: `presentaciones/${idPresentacion}/archivo`,
                params: { idTipo: 2 }
            })
        }),
        getNominaDownloadUrl: builder.query<
            GetNominaDownloadUrlResponse,
            { idPresentacion: number; idTipo: number }
        >({
            query: ({ idPresentacion, idTipo }) => ({
                url: `presentaciones/${idPresentacion}/archivo`,
                params: { idTipo: idTipo }
            })
        })
    })
});

export const {
    useGetTiposPresentacionesQuery,
    useGetTipoPresentacionQuery,
    useCreatePresentacionMutation,
    useGetPresentacionesByEstablecimientoQuery,
    useLazyGetPresentacionesByEstablecimientoQuery,
    useGetPresentacionQuery,
    useEditPresentacionMutation,
    useUpdatePresentationStateMutation,
    useGetDatosParametricosQuery,
    useGetPesentationsIndicatorsQuery,
    useLazyGetPresentacionRGRLPDFbyIdQuery,
    useLazyGetNominaDownloadUrlQuery
} = presentacionesApi;
