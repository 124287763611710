import React, { useState } from 'react';
import { Grid, Toolbar, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchInput from '../layout/SearchInput';

interface SearchProps {
    onChange: any;
    children: React.ReactElement;
    inputSearchName: string;
    placeHolder?: string;
    onClick?: any;
    clearFilters?: any;
    hideFilterButton?: boolean;
    hiddeButtons?: boolean;
    label?: string | null;
    type?: string;
    width?: string;
    showMoreFiltersButton?: boolean;
    isShowMoreFiltersOpen?: boolean;
    handleShowMoreFiltersPress?: () => void;
    disabled?: boolean;
    showSearchInput?: boolean;
}

const SearchToolbar = ({
    onChange,
    children,
    clearFilters,
    inputSearchName,
    placeHolder,
    onClick,
    hiddeButtons,
    hideFilterButton = false,
    label = null,
    type,
    width,
    showMoreFiltersButton = false,
    isShowMoreFiltersOpen = false,
    handleShowMoreFiltersPress,
    disabled = false,
    showSearchInput = true
}: SearchProps) => {
    const { t } = useTranslation('userForm');
    const [openFilters, setOpenFilters] = useState<boolean>(true);

    return (
        <>
            <Grid
                mb={3}
                sx={{
                    background: '#FFFFFF',
                    borderRadius: '0px 0 5px 5px'
                }}
            >
                <Toolbar>
                    <Grid alignItems="center" container>
                        <Typography variant="h5" sx={{pl: .5, pb: 1, fontWeight: 600, color: '#476773'}}>
                            {t('Filtrar por')}
                        </Typography>
                        <Grid item xs={12} gap={2.5} display={'flex'} width={'100%'}>
                            {showSearchInput && (
                                <SearchInput
                                    background={'#FFFFFF'}
                                    handleSearch={onChange}
                                    name={inputSearchName}
                                    placeHolder={placeHolder}
                                    label={label}
                                    type={type}
                                    width={width}
                                />
                            )}
                            {children}
                        </Grid>
                    </Grid>
                </Toolbar>

                {openFilters && (
                    <Toolbar>
                        <Grid container spacing={0} textAlign={'left'}>
                            {showMoreFiltersButton && (
                                <Grid item xs={12}>
                                    <Button
                                        startIcon={
                                            isShowMoreFiltersOpen ? <RemoveIcon /> : <AddIcon />
                                        }
                                        name="toolbar_clear_button"
                                        variant="text"
                                        onClick={handleShowMoreFiltersPress}
                                    >
                                        {isShowMoreFiltersOpen
                                            ? 'Ver menos filtros'
                                            : 'Ver más filtros'}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={0} textAlign={'right'}>
                            {!hiddeButtons && (
                                <>
                                    <Grid item xs={12}>
                                        <Button
                                            name="toolbar_clear_button"
                                            variant="text"
                                            onClick={() => clearFilters()}
                                        >
                                            Limpiar Filtros
                                        </Button>

                                        <Button
                                            name="toolbar_search_button"
                                            variant="outlined"
                                            disabled={disabled}
                                            onClick={() => onClick()}
                                            sx={{ marginLeft: '1rem' }}
                                        >
                                            Buscar
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Toolbar>
                )}
            </Grid>
        </>
    );
};

export default SearchToolbar;
