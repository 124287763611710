import { Box, IconButton, Typography } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import SectionHeader from "../SectionHeader";

const DrawerHeader = ({ onIconClick, text }: any) => (
    <Box>
        <SectionHeader containerType="drawer">
            <IconButton onClick={onIconClick}>
                <ArrowBack color="primary" />
            </IconButton>
            <SectionHeader.DrawerTitle>
                <Typography variant="h3" sx={{ fontWeight: '700', mb: 0, textAlign: 'left', fontSize: '20px' }}>
                    {text}
                </Typography>
            </SectionHeader.DrawerTitle>
        </SectionHeader>
    </Box>
);

export {DrawerHeader};